import { render, staticRenderFns } from "./SwitchAndNumberInput.vue?vue&type=template&id=84c0f27c&"
import script from "./SwitchAndNumberInput.vue?vue&type=script&lang=js&"
export * from "./SwitchAndNumberInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports