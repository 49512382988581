<template>
  <div class="d-flex">
    <SwitchLg
      class="pr-10"
      bgColor="switcherViolet"
      v-bind="switchProps"
      v-on="$listeners"
      v-model="visible"
    />
  
    <InputNumberDefault
      v-if="visible"
      v-bind="inputProps"
      v-on="$listeners"
      v-model="inputValue"
      :min="min"
      :step="step"
      @focusLost="checkValue"
    />
  </div>
</template>

<script>
import SwitchLg from '@/components/controls/SwitchLg'
import InputNumberDefault from '@/components/inputs/InputNumberDefault'

export default {
  name: 'SwitchAndNumberInput',
  inheritAttrs: false,
  components: { SwitchLg, InputNumberDefault },
  props: {
    value: {
      type: String | Number,
      required: true
    },
    switchProps: {
      type: Object,
      default: () => ({})
    },
    inputProps: {
      type: Object,
      default: () => ({})
    },
    min: {
      type: String | Number,
      default: 1
    },
    step: {
      type: String | Number,
      default: 1
    },
  },
  data: () => ({
    visible: false,
  }),
  computed: {
    inputValue: {
      get() {
        return +this.value
      },
      set(value) {
        this.$emit('input', +value)
      },
    },
  },
  methods: {
    checkValue() {
      if (!this.inputValue) {
        this.visible = false
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!!value) {
          this.visible = true
        } else if (value === null) {
          this.visible = false
        }
      },
    },
    visible: {
      handler(value) {
        this.inputValue = value ? this.value : null
      },
    },
  },
}
</script>