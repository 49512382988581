import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getBuyers = params => axios.get(`${getAccountId()}/buyers/paginated`, { params })
const getAllBuyers = () => axios.get(`${getAccountId()}/buyers/all`)
const getDetailBuyer = buyerId => axios.get(`${getAccountId()}/buyers/${buyerId}`)
const getBuyerCounters = params => axios.get(`${getAccountId()}/buyers/counters`, { params })
const resetBuyerCaps = buyerId => axios.delete(`${getAccountId()}/buyers/${buyerId}/caps`)
const createBuyer = formData => axios.post(`${getAccountId()}/buyers`, formData)
const updateBuyer = ({ buyerId, formData }) => axios.patch(`${getAccountId()}/buyers/${buyerId}`, formData)
const deleteBuyer = buyerId => axios.delete(`${getAccountId()}/buyers/${buyerId}`)

export {
  getBuyers, getAllBuyers, getDetailBuyer, getBuyerCounters, resetBuyerCaps, createBuyer, updateBuyer, deleteBuyer
}