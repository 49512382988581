<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">buyer</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage buyers</ActionButton>
      </div>
      
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
    <v-card
      class="create-card cardBg py-15 px-12 rounded-20"
      :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
      flat
      outlined
    >
      <div class="setup-content">
        <v-row class="d-block d-md-flex align-center" no-gutters>
          <v-col cols="12" md="4" lg="2" class="pt-0 pt-md-26">
            <TextDescription :description="description[0]" />
          </v-col>
          <v-col cols="12" md="8" lg="10">
            <v-form ref="form" v-model="validateForm">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="5" md="4" lg="3"
                >
                  <span class="font-normal nameField--text">Buyer name</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="buyerName"
                  ></portal-target>
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <FormField
                    v-model="formDataBuyer.name"
                    :rules="rules"
                    :loading="loadingDetailBuyer"
                    class="field-container"
                  >
                    <template #message="{ key, message }">
                      <portal to="buyerName" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </FormField>
                </v-col>
              
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="5" md="4" lg="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Timezone</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="detailBuyerTimezone"
                  ></portal-target>
                </v-col>
              
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <AutocompleteField
                    class="field-container"
                    v-model="formDataBuyer['timezone_id']"
                    :items="timezones"
                    item-text="display"
                    item-value="id"
                    :rules="rules"
                    checkBg
                  >
                    <template #message="{ key, message }">
                      <portal to="detailBuyerTimezone" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </AutocompleteField>
                </v-col>

                <v-col
                  class="d-flex align-center justify-start justify-sm-end pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="5" md="4" lg="3"
                >
                  <span class="font-large sectionTitle--text text-sm-right">Permissions</span>
                </v-col>
                <v-col class="pb-0 pb-sm-12" cols="12" sm="7" md="8" lg="9"></v-col>
              
                <v-col
                  class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                  cols="12" sm="5" md="4" lg="3"
                >
                  Allow buyer to pause Destinations
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <SwitchLg
                    v-model="formDataBuyer['pause_targets']"
                    bgColor="switcherViolet"
                  />
                </v-col>
              
                <v-col
                  class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                  cols="12" sm="5" md="4" lg="3"
                >
                  Allow buyer to set concurrency
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <SwitchLg
                    v-model="formDataBuyer['set_concurrency']"
                    bgColor="switcherViolet"
                  />
                </v-col>
              
                <v-col
                  class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                  cols="12" sm="5" md="4" lg="3"
                >
                  Allow buyer to dispute call conversions
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <SwitchLg
                    v-model="formDataBuyer['dispute_conversion']"
                    bgColor="switcherViolet"
                  />
                </v-col>
              
                <v-col
                  class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                  cols="12" sm="5" md="4" lg="3"
                >
                  Allow buyer to set capacity
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <SwitchLg
                    v-model="formDataBuyer['set_capacity']"
                    bgColor="switcherViolet"
                  />
                </v-col>

                <v-col
                  class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                  cols="12" sm="5" md="4" lg="3"
                >
                  Allow buyer to hangup calls
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <SwitchLg
                    v-model="formDataBuyer['hangup_calls']"
                    bgColor="switcherViolet"
                  />
                </v-col>

                <v-col
                  class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                  cols="12" sm="5" md="4" lg="3"
                >
                  Allow buyer to block numbers
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <SwitchLg
                    v-model="formDataBuyer['block_numbers']"
                    bgColor="switcherViolet"
                  />
                </v-col>

                <v-col
                  class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                  cols="12" sm="5" md="4" lg="3"
                >
                  Allow buyer to download reports
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <SwitchLg
                    v-model="formDataBuyer['download_reports']"
                    bgColor="switcherViolet"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      
        <v-row class="d-block d-md-flex align-center" no-gutters>
          <v-col cols="12" md="8" lg="10" offset="0" offset-md="4" offset-lg="2">
            <v-row no-gutters>
              <v-col
                class="d-flex align-center justify-sm-end pr-sm-11 pt-4 pb-4 pb-sm-10"
                cols="12" sm="5" md="4" lg="3"
              >
                <span class="font-large sectionTitle--text text-sm-right">CAP SETTINGS</span>
              </v-col>
              <v-col class="pb-0 pb-sm-10" cols="12" sm="7" md="9"></v-col>
            
              <v-col
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="4" lg="3"
              >
                Global Revenue Cap, $
              </v-col>
            
              <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                <SwitchAndNumberInput
                  v-model="revenueCapacity['global_revenue_cap']"
                  :min="0"
                  :step="0.01"
                />
              </v-col>
            
              <v-col
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="4" lg="3"
              >
                Monthly Revenue Cap, $
              </v-col>
            
              <v-col class="pb-10 d-flex" cols="12" sm="7" md="8" lg="9">
                <SwitchAndNumberInput
                  v-model="revenueCapacity['monthly_revenue_cap']"
                  :min="0"
                  :step="0.01"
                />
              </v-col>
            
              <v-col
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="4" lg="3"
              >
                Daily Revenue Cap, $
              </v-col>
            
              <v-col class="pb-10 d-flex" cols="12" sm="7" md="8" lg="9">
                <SwitchAndNumberInput
                  v-model="revenueCapacity['daily_revenue_cap']"
                  :min="0"
                  :step="0.01"
                />
              </v-col>
            
              <v-col
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="4" lg="3"
              >
                Hourly Revenue Cap, $
              </v-col>
            
              <v-col class="pb-10 d-flex" cols="12" sm="7" md="8" lg="9">
                <SwitchAndNumberInput
                  v-model="revenueCapacity['hourly_revenue_cap']"
                  :min="0"
                  :step="0.01"
                />
              </v-col>
            
              <v-col cols="12" sm="7" md="8" lg="9" offset="0" offset-sm="5" offset-md="4" offset-lg="3" class="mt-2 mt-sm-8">
                <CancelButton @click="closeAction" class="mr-8 mr-sm-10">Cancel</CancelButton>
              
                <ActionButton @click="submitForm" :loading="loadingAction">{{ buyerSlug ? 'Update' : 'Create' }}</ActionButton>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'
import SwitchLg from '@/components/controls/SwitchLg'
import SwitchAndNumberInput from '@/components/controls/SwitchAndNumberInput'

import { mapState } from 'vuex'

import { getDetailBuyer, createBuyer, updateBuyer } from '@/api/buyer-routes'

export default {
  name: 'DetailBuyer',
  components: { ActionButton, CancelButton, TextDescription, FormField, AutocompleteField, SwitchLg, SwitchAndNumberInput },
  data: () => ({
    validateForm: true,
    formDataBuyer: {
      name: null,
      'timezone_id': null,
      'pause_targets': false,
      'set_concurrency': false,
      'dispute_conversion': false,
      'set_capacity': false,
      'hangup_calls': false,
      'block_numbers': false,
      'download_reports': false,
    },
    revenueCapacity: {
      'global_revenue_cap': null,
      'monthly_revenue_cap': null,
      'daily_revenue_cap': null,
      'hourly_revenue_cap': null,
    },
    rules: [],
    description: [
      {
        title: 'Setup Your buyer',
        text: []
      },
    ],
    buyerSlug: null,
    loadingDetailBuyer: false,
    loadingAction: false,
  }),
  computed: {
    ...mapState({
      timezones: state => state.timezones,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.buyerSlug = this.$route.params.slug
      this.loadDetailBuyer()
    }
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'ManageBuyer'})
    },
    async loadDetailBuyer() {
      this.loadingDetailBuyer = true

      const { success, payload, message } = await getDetailBuyer(this.buyerSlug)

      if (success) {
        this.formDataBuyer = {
          name: payload.name,
          'timezone_id': payload['timezone_id'],
          'pause_targets': !!payload['pause_targets'],
          'set_concurrency': !!payload['set_concurrency'],
          'dispute_conversion': !!payload['dispute_conversion'],
          'set_capacity': !!payload['set_capacity'],
          'hangup_calls': !!payload['hangup_calls'],
          'block_numbers': !!payload['block_numbers'],
          'download_reports': !!payload['download_reports'],
        }

        Object.keys(this.revenueCapacity).forEach(name => {
          this.revenueCapacity[name] = payload[name]
        })
        
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingDetailBuyer = false
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        Object.keys(this.revenueCapacity).forEach(name => {
          this.revenueCapacity[name] = !!Number(this.revenueCapacity[name]) ? this.revenueCapacity[name] : null
        })
        
        let formData = Object.assign({}, this.formDataBuyer, this.revenueCapacity)
        
        if (!!this.buyerSlug) {
          this.updateBuyer(formData)
        } else {
          this.createBuyer(formData)
        }
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    async createBuyer(formData) {
      this.loadingAction = true

      const { success, message } = await createBuyer(formData)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.closeAction()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    async updateBuyer(formData) {
      this.loadingAction = true

      const { success, message } = await updateBuyer({
        buyerId: this.buyerSlug,
        formData
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.closeAction()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
  },
}
</script>
