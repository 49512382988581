<template>
  <div
    class="input-number-default-container"
    :style="{ 'max-width': maxWidthInput }"
  >
    <v-text-field
      v-bind="$attrs"
      v-on="$listeners"
      class="input-number-default font-normal"
      color="inputNumberDefaultColor"
      v-model.number="fieldValue"
      @focus="changeFocus(true)"
      @blur="changeFocus(false)"
      @keypress="onlyNumbers"
      @change="checkValue"
      type="number"
      :min="min"
      :step="step"
      dense
    >
      <template #append v-if="focusField">
        <div class="icons-container d-flex flex-column">
          <v-icon
            color="defaultIconColor"
            size="10"
            @click="inc"
          >$arrowUp</v-icon>
          <v-icon
            color="defaultIconColor"
            size="10"
            @click="dec"
          >$arrowDown</v-icon>
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'InputNumberDefault',
  inheritAttrs: false,
  props: {
    value: {
      type: Number | String,
      required: true
    },
    min: {
      type: Number | String,
      default: 1
    },
    step: {
      type: Number | String,
      default: 1
    }
  },
  data: () => ({
    focusField: false,
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', +value)
      }
    },
    maxWidthInput() {
      const str = this.fieldValue
      const length = str.toString().length ? str.toString().length -1 : 0
      const width = 35 //this.focusField ? 32 : 22
      return width + length * 7.3 + 'px'
    }
  },
  methods: {
    inc() {
      this.fieldValue = +(parseFloat(this.fieldValue) + parseFloat(this.step)).toFixed(2)
    },
    dec() {
      if (this.fieldValue > this.min) {
        this.fieldValue = +(parseFloat(this.fieldValue) - parseFloat(this.step)).toFixed(2)
      }
    },
    changeFocus(val) {
      this.focusField = val
      if (!val) {
        this.$emit('focusLost')
      }
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$')
      const key = `${this.fieldValue}` + String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (isNaN(key) || !regex.test(parseFloat(key))) {
        event.preventDefault()
        return false
      }
    },
    checkValue(val) {
      if (val === '' || val < this.min) {
        this.fieldValue = this.min
      }
    }
  }
}
</script>

<style lang="scss">
.input-number-default-container {
  
  
  & > .input-number-default {
    margin: 0!important;
    padding: 0!important;
  
    & > .v-input__control {
      & > .v-input__slot {
        &::before {
          position: absolute;
          bottom: -3px;
          border: 1px solid var(--v-inputNumberDefaultUnderscore-base)!important;
          width: calc(100% - 10px);
        }
      
        &::after {
          transform: none;
          border: none;
        }
      
        & > .v-text-field__slot {
          & > input {
            padding: 0 0 0 9px!important;
          }
        }
  
        & > .v-input__append-inner {
          & > .icons-container {
            svg:first-child {
              margin-bottom: -2px;
            }
            svg:last-child {
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
}
</style>